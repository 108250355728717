/* You can add global styles to this file, and also import other style files */
//@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./styles/styles.scss";
//@import '~@nimbus/styles/fonts/fontawesome.scss';
//@import '~@nimbus/styles/fonts/roboto.scss';

//html, body { height: 100%; }
//body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

$cell-odd-background-color: lightyellow;
$row-mouse-hover-color: lightgreen;

/* make sure to add the @import the SlickGrid Bootstrap Theme AFTER the variables changes */
@import '@slickgrid-universal/common/dist/styles/sass/slickgrid-theme-bootstrap.scss';
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

// mat-button-toggle-group {
//     flex-wrap: wrap;
//     width: fit-content;
// }

// .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
//     border: solid 1px rgba(0,0,0,.12);
//     margin: 0px 0.5rem;
// }

// .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle:first-child {
//     border: solid 1px rgba(0,0,0,.12) !important;
//     margin: 0px 0.5rem;
// }

// .mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
//     border: none; 
// }

.overflow-container {
    overflow: scroll;
}

.card-container {
    padding: 0px;
    border-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid #515151;
    box-shadow: 0px 0px 3px 1px #51515125;
}

.card-header {
    background-color: #515151;
}

.card-content-leading,
.card-content-description,
.card-header span,
.card-content-header {
    font-family: 'Inter', sans-serif;

}

.card-header span {
    color: white;
    font-size: 18px;
    font-weight: 600;
}

.card-content-header {
    font-size: 16px;
    font-weight: 600;
    color: #393939;
}

.card-content-leading {
    font-size: 12px;
    font-weight: 300;
}

.card-content-description {
    font-size: 14px;
    font-weight: 400;
}

.mat-main-button {
    background-color: #F5761A;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.mat-main-cancel-button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #6d6d6d;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.otp-input {
    width: 35px;
    text-align: center;
    padding: 0.25rem;
    outline: none;
    border-radius: 0.5rem;
    border: 1px solid #8e8e8e;
    transition: 0.3s;
}

.otp-input:focus {
    border: 1px solid #269ed5;
    box-shadow: 0px 0px 5px 2px #00000014;
}

.otp-input::-webkit-outer-spin-button,
.otp-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-input[type=number] {
    -moz-appearance: textfield;
}

// css class for angular slickgrid

.table-cell {

    // background-color: #9c1515;
    // background: #c27474;
    font-size: 30px;
    font-weight: 800;
    color: rgb(24, 24, 94);
}

//, .slick-header-column
.table-cell-header {
    // background-color: #f00;
    color: rgb(24, 24, 94) !important;
    background: #c6bde2 !important;
}

.table-cell1 {
    // background-color: #9c1515;
    // background: #c27474;
    font-size: 30px;
    font-weight: 800;
    color: rgb(27, 56, 20);
}

.table-cell-header1 {
    // background-color: #f00;
    color: rgb(27, 56, 20) !important;
    background: #8bee72 !important;
}

.table-cell1Summery {
    // background-color: #9c1515;
    // background: #c27474;
    font-size: 30px;
    font-weight: 800;
    color: rgb(15, 15, 15);
}

.table-cell-headerSummery {
    // background-color: #f00;
    // color: rgb(27, 56, 20) !important;
    background: #adb3b0 !important;
}